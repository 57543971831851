.layout {
  &.legacyDashboard {
    overflow: hidden;
  }

  &.service {
    align-items: center;
    justify-content: center;
    background: palette(gray10);
  }
}
