/* stylelint-disable no-descending-specificity, selector-pseudo-class-no-unknown */
@import '~styles/palette';

.headerBottom {
  position: relative;
  background-color: palette(white);

  &_container {
    display: flex;
    align-items: center;
    min-height: 64px;
  }

  &_title {
    padding-left: 13px;
    font-size: 24px;
    font-weight: 700;
    color: palette(gray80);
    letter-spacing: 0.02em;
    cursor: pointer;
    user-select: none;
  }

  &_dropdown {
    position: absolute;
    z-index: 6;
    display: block;
    width: 100%;
    padding: 5px 0 20px;
    visibility: hidden;
    background-color: palette(white);
    border-radius: 0;
    box-shadow: 0 5px 5px rgba(palette(gray40), 30%);
    opacity: 0;
    transition: opacity 300ms, visibility 300ms, transform 300ms;
    transform: translateY(-20px);

    &.show {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }

    &_container {
      position: relative;
      display: flex;

      &::before {
        position: absolute;
        top: 0;
        left: 15px;
        width: calc(100% - 30px);
        content: '';
        border-top: 1px solid palette(gray20);
      }
    }

    &_content {
      flex: 1 1 auto;
      padding: 20px 30px 20px 10px;
      margin-top: 10px;
      margin-right: -8px;

      &:not(.intelligence) {
        mask: linear-gradient(
          to bottom,
          transparent 0,
          white 20px,
          white calc(100% - 20px),
          transparent 100%
        );
      }
    }
  }
}

.buttonNewDashboard {
  position: absolute;
  top: 30px;
  right: 30px;
}
