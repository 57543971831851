@import '~styles/scrollbar';

.legends {
  display: flex;
  gap: 24px;
  padding-left: 50px;
}

.wrap {
  display: grid;
  row-gap: 12px;

  &.center {
    .legends {
      justify-content: center;
      padding-left: 0;
    }
  }
}
