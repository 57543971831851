/* stylelint-disable no-descending-specificity */
@import '~styles/palette';

.highcharts {
  &-series {
    transition: opacity 350ms cubic-bezier(0.455, 0.03, 0.515, 0.955),
      visibility 350ms cubic-bezier(0.455, 0.03, 0.515, 0.955);

    &[visibility='hidden'] {
      opacity: 0;
    }
  }

  &-tooltip {
    z-index: 10;
    line-height: 1.72;

    > span {
      padding: 13px 20px;
      background: palette(white);
      border-radius: 2px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 4%), 0 1px 2px rgba(0, 0, 0, 5%),
        0 1px 3px rgba(0, 0, 0, 7%), 0 4px 14px rgba(0, 0, 0, 10%);
    }

    &_header {
      margin-bottom: 3px;
      font-weight: 500;
      color: palette(gray60);
      text-transform: uppercase;
      letter-spacing: 0.06em;
    }

    &_title {
      margin-bottom: 4px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 1.7143;
      color: palette(green60);
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 0.06em;
    }

    &_table {
      width: 100%;
      border-spacing: 0;

      td {
        padding: 0;

        &:last-child {
          padding-left: 10px;
          font-weight: bold;
          text-align: right;
        }
      }

      &--customer {
        tr:first-child td {
          font-weight: 400;
          border-bottom: 0;
        }

        tr:last-child td {
          border-bottom: 0;
        }

        td {
          padding: 4px 0 4px 12px;
          font-weight: 700;
          text-align: right;
          border-bottom: 1px solid #e6ecf0;

          &:first-child {
            padding-left: 0;
            font-weight: 400;
            text-align: left;
          }

          &:last-child {
            padding-left: 12px;
            font-weight: 700;
          }
        }
      }
    }

    &_item {
      &--mcd,
      &--yoy {
        td:last-child {
          color: palette(negative50);
        }
      }

      &--market,
      &--sales {
        td:last-child {
          color: palette(green70);
        }
      }
    }
  }

  &-legend {
    &-item {
      transition: opacity 250ms;

      .highcharts-graph:not(:only-child) {
        display: none;
      }

      image {
        width: 24px;
        height: 24px;
        transform: translate(-17px, -13px);
      }

      span {
        transition: color 250ms;
      }

      &-hidden {
        opacity: 0.5;
      }
    }
  }
}
