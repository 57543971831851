@import '~styles/palette';

.top {
  padding: 0 30px 34px 16px;
  border-bottom: 1px solid palette(gray20);

  h3 {
    margin-bottom: 16px;
    color: palette(black);
  }

  p {
    max-width: 311px;
    line-height: 1.34;
    color: palette(gray80);
    letter-spacing: 0.01em;
  }
}

.bottom {
  padding: 29px 30px 10px 16px;

  li {
    display: grid;
    grid-template-columns: 24px auto;
    column-gap: 12px;
    margin-top: 17px;
    letter-spacing: 0.01em;

    svg {
      color: palette(green60);
    }
  }

  a:hover {
    color: palette(green70);
  }
}
