@import '~styles/palette';
@import '~styles/scrollbar';

.popUp {
  position: absolute;
  top: calc(100%);
  right: -10px;
  z-index: 10;
  width: 341px;
  padding-right: 4px;
  visibility: hidden;
  background: palette(white);
  border-radius: 12px;
  box-shadow: 0 6px 24px rgba(206, 212, 218, 30%);
  opacity: 0;
  transition-timing-function: ease-in-out;
  transition-duration: 150ms;
  transition-property: opacity, visibility, top;

  &::before {
    position: absolute;
    top: -8px;
    right: 33px;
    width: 0;
    height: 0;
    content: '';
    border-right: 8px solid transparent;
    border-bottom: 8px solid palette(white);
    border-left: 8px solid transparent;
  }

  &.show {
    top: calc(100% + 14px);
    visibility: visible;
    opacity: 1;
  }
}

.rowWrapper {
  @include scrollbar;

  height: 275px;
  padding: 8px 0;
  overflow: auto;
  border-radius: 12px;
  scrollbar-color: rgba(palette(black), 0.2) transparent;

  &.empty {
    height: 145px;
  }

  &::-webkit-scrollbar-track {
    margin: 10px 0;
  }
}

.noExport {
  display: flex;
  flex-direction: column;
  row-gap: 11px;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 14px;
  line-height: 1.7;
  color: palette(gray50);
}
