@import '~styles/palette';

.chartLineWrapper {
  display: grid;
  grid-template-rows: 38px 1fr;
  row-gap: 13px;
}

.chartLineTop {
  display: grid;
  grid-auto-flow: column;
  gap: 15px;
  align-items: center;
  justify-content: start;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  color: palette(gray50);
  text-transform: uppercase;
  letter-spacing: 0.06em;
}
