@import '~styles/palette';

.headerTop {
  font-size: 15px;
  color: palette(white);
  background-color: palette(green50);

  &_container {
    display: grid;
    grid-auto-flow: column;
    column-gap: 20px;
    align-items: center;
    justify-content: space-between;
    min-height: 64px;
  }

  &_actions {
    position: relative;
    display: grid;
    grid-auto-flow: column;
    column-gap: 20px;
    align-items: center;
    min-height: 32px;
    font-size: 16px;
    color: palette(white);

    &::before {
      width: 1px;
      height: 32px;
      content: '';
      background-color: rgba(palette(white), 0.5);
    }

    button {
      letter-spacing: 0.01em;
      outline: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}
