@import '~styles/palette';

.hamburgerButton {
  $this: &;

  width: 40px;
  height: 40px;
  margin-left: -10px;

  &:focus {
    outline: none;
  }

  &_icon {
    fill: none;
    transition: 400ms;
    transform: rotate(0);
  }

  &_line {
    fill: none;
    stroke: palette(gray70);
    stroke-dashoffset: 0;
    stroke-linecap: round;
    stroke-width: 5.5;
    transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;

    &_top {
      stroke-dasharray: 40 160;
    }

    &_middle {
      stroke-dasharray: 40 142;
      transition: transform 400ms;
      transform-origin: 50%;
    }

    &_bottom {
      stroke-dasharray: 40 85;
      transition: transform 400ms, stroke-dashoffset 400ms;
      transform-origin: 50%;
    }
  }

  &[aria-expanded='true'] {
    #{$this}_icon {
      transform: rotate(45deg);
    }

    #{$this}_line {
      &_top {
        stroke-dashoffset: -64px;
      }

      &_middle {
        transform: rotate(90deg);
      }

      &_bottom {
        stroke-dashoffset: -64px;
      }
    }
  }
}
