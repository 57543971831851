@import '~styles/palette';

.wrapper {
  position: relative;
}

.btnExport {
  position: relative;
  display: flex;
  gap: 8px;
  align-items: center;
  transition: color 150ms;

  &.notification::before,
  &.notification::after {
    position: absolute;
    top: 0;
    left: 24px;
    content: '';
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }

  &.notification::before {
    width: 5px;
    height: 5px;
    background-color: palette(green90);
  }

  &.notification::after {
    width: 3px;
    height: 3px;
    background-color: palette(white);
    transform: translate(-50%, -50%);
    animation: opacity-export-animation 1500ms infinite;
  }
}

@keyframes opacity-export-animation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}
