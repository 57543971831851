/* stylelint-disable no-descending-specificity */
@import '~styles/palette';
@import '~styles/scrollbar';

.wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 6;
  width: 240px;
  padding: 0 16px 16px;
  visibility: hidden;
  background: palette(white);
  border-radius: 24px;
  box-shadow: 0 3px 8px rgba(palette(black), 12%),
    0 8px 24px rgba(palette(black), 7%);
  opacity: 0;
  transition: all 250ms ease-out;
  transform: translateY(-5px);

  &.isVisible {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }

  &.header {
    top: -5px;
    right: 0;
    left: auto;
  }
}

.head {
  display: grid;
  place-content: center;
  height: 46px;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  color: palette(gray60);
  text-transform: uppercase;
  letter-spacing: 0.06em;
  border-bottom: 1px solid palette(gray20);
}

.body {
  @include scrollbar;

  display: grid;
  row-gap: 4px;
  max-height: 348px;
  overflow-y: auto;
}

.option {
  position: relative;
  display: grid;
  grid-template-columns: 40px auto;
  column-gap: 12px;
  align-items: center;
  width: 100%;
  padding-right: 8px;
  text-align: left;
  border-radius: 8px;
  transition: background-color 250ms;

  &:hover:not(.active)::after,
  &:focus::after {
    position: absolute;
    z-index: -2;
    width: 100%;
    height: 100%;
    content: '';
    background-color: palette(gray10);
    border-radius: 8px;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    outline: none;
  }

  &.active .optionText {
    font-weight: 500;
    color: palette(green60);
    cursor: default;
  }

  img {
    z-index: -1;
  }
}

.optionText {
  z-index: -1;
  padding: 8px 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.33;
  color: palette(gray100);
}
