@import '~styles/palette';

.header {
  margin-top: 16px;
  margin-bottom: 6px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  color: palette(gray100);
  text-transform: uppercase;
  letter-spacing: 0.06em;
}

.navList {
  margin-left: -12px;
}
