@import '~styles/palette';

.button {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 8px;
  align-items: center;
  font-size: 14px;
  color: palette(gray90);
  text-transform: uppercase;
  letter-spacing: 0.06em;
  visibility: hidden;
  opacity: 0;
  transition: all 300ms;

  svg {
    color: palette(gray40);
    transition: color 200ms;
  }

  &:hover,
  &:focus {
    outline: none;

    svg {
      color: palette(gray50);
    }
  }

  &.visible {
    visibility: visible;
    opacity: 1;
  }

  &.chartBar {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1000;
    padding-left: 8px;
    background-color: palette(white);
  }
}
