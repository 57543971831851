@import '~styles/palette';

.wrap {
  font-weight: 500;
  color: palette(gray70);
  text-align: center;

  &.error {
    color: palette(orange60);
  }

  &.margin {
    margin-top: 45px;
  }

  &.changeBrand {
    margin: 20px 0 10px;
  }

  &.insightsBriefings {
    display: grid;
    align-items: center;
    height: 180px;
    font-size: 22px;
  }

  &.settings {
    display: grid;
    place-content: center;
    height: 388px;
  }

  &.center {
    display: grid;
    place-content: center;
    height: 100%;
  }

  &.tab {
    display: grid;
    place-content: center;
    min-height: 550px;
  }

  &.card {
    margin: 30px 0;
  }

  &.subcard {
    display: grid;
    place-content: center;
    min-height: 380px;
  }
}
