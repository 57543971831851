@import '~styles/palette';
@import '~styles/scrollbar';

.wrap {
  position: relative;
  max-width: 812px;
  min-height: 390px;
  margin: -2.5px -16px 0;

  & > div {
    @include scrollbarBig;

    overflow-x: auto !important;
  }

  &.trackerBuilder {
    max-width: 1080px;
    min-height: 377px;
  }
}
