@import '~styles/palette';

.legend {
  display: flex;
  gap: 8px;
  align-items: center;
  transition: opacity 300ms;

  &::before {
    display: block;
    width: 14px;
    height: 14px;
    content: '';
    background-color: currentcolor;
    border-radius: 50%;
  }

  &.inactive {
    opacity: 0.3;
  }
}

.title {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
  color: palette(black);
  letter-spacing: 0.01em;
}
