@import '~styles/palette';

.title {
  font-size: 14px;
  font-weight: 500;
  color: palette(gray50);
  text-transform: uppercase;
  letter-spacing: 0.84px;
}

.titleText {
  width: max-content;
  max-width: 400px;
  white-space: normal;
}

.titleBlock {
  display: flex;

  &.center {
    justify-items: center;
  }

  :nth-child(2) {
    justify-self: right;
  }
}

.content {
  width: 100%;
  margin-top: 5px;
  font-size: 14px;
  line-height: 1.71;
  color: palette(gray300);
  letter-spacing: 0.14px;

  &.divider {
    tr:not(:first-child, :last-child) {
      border-bottom: 1px solid palette(gray200);
    }
  }
}

.tableHead {
  padding-bottom: 3px;

  &:nth-child(n + 2) {
    padding-left: 20px;
    text-align: center;
  }
}

.value {
  padding-left: 20px;
  font-weight: 700;
  text-align: right;
  letter-spacing: 0.14px;

  &.thin {
    font-weight: 400;
  }

  &.center {
    text-align: center;
  }
}
