@import '~styles/palette';

.option {
  position: relative;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-between;
  min-height: 32px;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.714;
  color: palette(gray70);
  text-transform: uppercase;
  cursor: pointer;
  background-color: white;
  transition: background-color 250ms, color 250ms;

  &:hover,
  &:focus-visible {
    color: palette(gray90);
    background-color: palette(gray10);
  }

  &:last-of-type {
    border-bottom: none;
  }
}

.optionCheck {
  color: palette(green70);
}
