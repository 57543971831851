.wrapper {
  position: relative;
  z-index: 5;
  width: 181px;

  &.alignLeft {
    margin-left: -12px;
  }
}

.arrow {
  transition: transform 200ms ease-in-out, color 150ms;

  &.open {
    transform: rotate(-180deg);
  }
}
