@import '~styles/palette';
@import '~styles/scrollbar';

.icon-close {
  margin-left: auto;
  color: palette(gray50);
  opacity: 0;
  transition: opacity 250ms, color 200ms;

  &:hover,
  &:focus {
    color: palette(gray60);
  }
}

.cs {
  $this: &;

  display: grid;
  grid-template-columns: 1fr 1fr;
  min-width: 1160px;
  min-height: 480px;
  background-color: palette(white);
  border-radius: 12px;
  box-shadow: 0 6px 24px rgba(palette(gray40), 30%);

  &__loader {
    grid-template-columns: auto;
  }

  &__left,
  &__right {
    display: flex;
    flex-direction: column;
    padding: 32px 40px;

    h3 {
      font-size: 28px;
      color: palette(green60);
    }
  }

  &.isModal {
    min-width: 800px;
    box-shadow: none;

    #{$this} {
      &__left {
        padding: 0 40px 0 0;
      }

      &__right {
        padding: 0 0 0 40px;
      }
    }
  }

  &__left {
    border-right: 1px solid palette(gray30);

    p {
      width: 22ch;
      margin-top: 23px;
      color: palette(gray60);
    }
  }

  &__list {
    position: relative;
    display: grid;
    flex-grow: 1;
    grid-auto-rows: 64px;
    row-gap: 12px;
    overflow-y: auto;

    &--limited {
      height: 444px;
      padding-right: 7px;
      @include scrollbar;
    }

    &__wrap {
      margin-top: 32px;
    }

    &-item {
      display: flex;
      align-items: center;
      padding-right: 20px;
      border-radius: 31px;
      transition: all 250ms;

      &-image {
        display: grid;
        place-items: center;
        width: 64px;
        height: 64px;
        margin-right: 20px;
        overflow: hidden;
        background-color: palette(white);
        border: 2px solid palette(gray20);
        border-radius: 50%;
        transition: all 250ms;
      }

      &:hover {
        background-color: palette(gray10);

        .cs__list-item-image {
          border-color: palette(green50);
        }

        button {
          opacity: 1;
        }
      }
    }
  }

  &__search {
    margin-top: 16px;
  }

  &__add-list {
    margin-top: 24px;

    &-inner {
      flex-grow: 1;
    }

    .v-list {
      @include scrollbar;
    }

    li {
      display: grid;
      grid-template-columns: 36px 1fr;
      column-gap: 28px;
      align-items: center;
      min-height: 48px;
      cursor: pointer;
      user-select: none;
      transition: background-color 250ms;

      + li {
        border-top: 1px solid palette(gray20);
      }

      &:hover,
      &:focus {
        background-color: palette(gray10);
      }

      img {
        width: 100%;
      }

      span {
        font-size: 14px;
        color: palette(gray80);
      }
    }
  }
}
