@import '~styles/palette';

.tooltip {
  position: absolute;
  bottom: calc(100% - 6px);
  left: 50%;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: visibility 250ms, opacity 250ms;
  transform: translateX(-50%);
}

.label {
  position: relative;
  display: block;
  width: 60px;
  padding-bottom: 100%;
  text-align: center;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: palette(gray10);
    border-radius: 4px;

    .tooltip {
      z-index: 1;
      visibility: visible;
      opacity: 1;
    }
  }

  &.first .tooltip {
    left: 0;
    transform: none;
  }

  &.last .tooltip {
    right: 0;
    left: inherit;
    transform: none;
  }

  &.disabled {
    cursor: default;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.tooltipContent {
  height: 24px;
  padding: 6px 9px;
  font-size: 14px;
  line-height: 1.72;
  color: palette(gray80);
  background-color: palette(white);
  border-radius: 4px;
  box-shadow: 0 4px 14px rgba(palette(black), 10%),
    0 1px 3px rgba(palette(black), 7%), 0 1px 2px rgba(palette(black), 5%),
    0 2px 5px rgba(palette(black), 4%);
}
