/* stylelint-disable selector-pseudo-class-no-unknown */
@import '~styles/palette';

.tabNav {
  flex: 0 0 202px;
  flex-direction: column;
  padding-top: 28px;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.02em;
  border-right: 1px solid palette(gray20);
}

.navLink {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  line-height: 1.33;
  color: palette(gray70);
  letter-spacing: 0.02em;
  outline: none;
  transition: color 200ms, padding 300ms;

  /* Right chevron */
  &::after {
    flex: 0 0 24px;
    width: 24px;
    height: 24px;
    margin-top: 0.5px; // fix alignment jumping while transition
    margin-right: 16px;
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 4L16 12L8 20' stroke='%2329BA6C' stroke-width='2'/%3E%3C/svg%3E%0A");
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
    transform: translateX(-5px);
  }

  &:hover,
  &:focus,
  &:global(.active) {
    color: palette(green60);

    &::after {
      opacity: 1;
      transform: translateX(0);
    }
  }

  &:global(.active) {
    padding: 24px 0;
    cursor: default;
  }
}

.navListItem {
  &:first-child {
    .navLink {
      padding-top: 4px;
    }
  }
}
