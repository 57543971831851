@import '~styles/palette';

.navLink {
  display: inline-block;
  padding: 6px 12px;
  line-height: 1.34;
  color: palette(gray80);
  text-align: left;
  border-radius: 4px;
  transition: color 250ms, background-color 250ms;

  &.active {
    font-weight: 500;
    color: palette(green60);
    cursor: default;
  }

  &.nested {
    margin-left: 20px;
  }

  &:disabled {
    color: palette(gray60);
  }

  &:hover {
    text-decoration: none;
  }

  &:not(:disabled, .active, .isDisabled) {
    &:hover,
    &:focus {
      color: palette(gray100);
      background-color: palette(gray10);
    }
  }

  &.isDisabled {
    font-weight: 400;
    color: palette(gray40);

    &:hover {
      color: palette(gray40);
      background-color: palette(gray10);
    }

    &:focus {
      color: palette(gray40);
      outline: none;
    }
  }
}
