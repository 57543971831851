@import '~styles/palette';

.createDashboard {
  min-width: 520px;
  margin-top: 8px;
  color: palette(gray70);
}

.descriptions {
  max-width: 480px;
}

.buttonsWrapper {
  display: grid;
  grid-auto-flow: column;
  column-gap: 24px;
  margin-top: 40px;
}
