@import '~styles/palette';

/* stylelint-disable number-max-precision */
.textOutline {
  position: relative;
  z-index: 2;
  // prettier-ignore
  text-shadow: palette(white) 3px 0 0,
    palette(white) 2.83487px 0.981584px 0,
    palette(white) 2.35766px 1.85511px 0,
    palette(white) 1.62091px 2.52441px 0,
    palette(white) 0.705713px 2.91581px 0,
    palette(white) -0.287171px 2.98622px 0,
    palette(white) -1.24844px 2.72789px 0,
    palette(white) -2.07227px 2.16926px 0,
    palette(white) -2.66798px 1.37182px 0,
    palette(white) -2.96998px 0.42336px 0,
    palette(white) -2.94502px -0.571704px 0,
    palette(white) -2.59586px -1.50383px 0,
    palette(white) -1.96093px -2.27041px 0,
    palette(white) -1.11013px -2.78704px 0,
    palette(white) -0.137119px -2.99686px 0,
    palette(white) 0.850987px -2.87677px 0,
    palette(white) 1.74541px -2.43999px 0,
    palette(white) 2.44769px -1.73459px 0,
    palette(white) 2.88051px -0.838247px 0;
}
