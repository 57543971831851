/* stylelint-disable selector-pseudo-class-no-unknown */
@import '~styles/palette';

.iconWrapper {
  padding: 11px;
  color: palette(orange60);
  background-color: palette(orange10);
  border-radius: 100%;
}

.gridButtons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 12px;
  width: 100%;
  margin-top: 24px;

  > :global(.btn) {
    min-width: 0;
  }

  &:not(.unavailable, .deleteOnSave) {
    padding: 0 28px;
  }
}
