/* stylelint-disable no-descending-specificity, selector-pseudo-class-no-unknown */
@import '~styles/palette';
@import '~styles/scrollbar';

.tabPane {
  padding-left: 22px;
  margin-left: -10px;

  &:global(.active) {
    display: grid !important;
  }

  &:not(.intelligence) {
    @include scrollbar;

    grid-template-columns: repeat(3, 1fr);
    column-gap: 24px;
    max-height: calc(100vh - 163px);
    padding: 20px 28px 20px 22px;
    margin: -20px -28px -20px -10px;
    overflow: auto;
  }

  &_header {
    padding: 5px 0 10px;
    margin-bottom: 20px;
    font-weight: 500;
    color: palette(green60);
    border-bottom: 1px solid palette(green40);
  }

  &_noNameGroup {
    padding-top: 19px;
    margin-top: 19px;
    border-top: 1px solid palette(green40);
  }
}
