@import '~styles/palette';

.report {
  display: flex;
  gap: 8px;
  width: 100%;
  padding: 12px 16px;
  background-color: palette(white);
  border-bottom: 1px solid palette(gray20);
  transition: background-color 150ms;

  &:last-child {
    border: none;
  }

  &.processing {
    pointer-events: none;
  }

  &:hover,
  &:focus {
    text-decoration: none !important;
    background-color: palette(gray10);
  }
}

.icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;

  &.newExport::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 5px;
    height: 5px;
    content: '';
    background: palette(green90);
    border-radius: 50%;
    transform: translate(50%, -50%);
  }

  &.ppt {
    background-color: palette(orange10);
  }

  &.excel {
    background-color: palette(green10);
  }

  &.failed {
    background-color: palette(gray10);
  }
}

.spinner {
  mask-image: linear-gradient(
    160deg,
    transparent 30%,
    transparent,
    palette(black) 80%,
    palette(black)
  );

  &.ppt {
    color: palette(orange50);
  }

  &.excel {
    color: palette(green50);
  }
}

.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: flex-start;
  max-width: 240px;
  margin-top: 1px;
  white-space: nowrap;
}

.title {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.7;
  color: palette(gray80);

  &.processing {
    position: relative;
    padding-right: 14px;

    &::after {
      position: absolute;
      display: inline-block;
      content: '';
      animation: dots steps(1, end) 1500ms infinite;
    }
  }
}

.subText {
  margin-top: -2px;
  font-size: 10px;
  font-weight: 500;
  line-height: 1.6;

  &.time {
    color: palette(gray45);
  }

  &.error {
    color: palette(orange50);
  }
}

.subscriptionIcon {
  padding: 4px;
  margin-top: 8px;
  margin-left: auto;
  color: palette(gray60);
  cursor: default;
  background-color: palette(gray20);
  border-radius: 50%;
  transition: color 250ms;

  &:hover,
  &:focus {
    color: palette(gray80);
  }
}

// TODO: move to global keyframes
@keyframes dots {
  0% {
    content: '';
  }

  25% {
    content: '.';
  }

  50% {
    content: '..';
  }

  75% {
    content: '...';
  }

  100% {
    content: '';
  }
}
