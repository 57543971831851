/* stylelint-disable selector-pseudo-class-no-unknown */
@import '~styles/palette';

.iconWrapper {
  padding: 10px;
  color: palette(yellow);
  background-color: rgba(palette(yellow), 0.25);
  border-radius: 100%;
}

.bottom {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  padding: 0 28px;
  margin-top: 24px;

  > :global(.btn) {
    min-width: 0;
  }
}
